<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>


          <router-link to="/partners" title="Partnerzy" class="breadcrumb">
            Partnerzy
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Partnerzy</h1>
        </div>
      </div>

      <div class="row row-db">
        <div class="col s12 m6 l4 xl3" v-for="user in users">
          <a class="partner" :href="user.link_website" target="_blank">
            <div class="partner__thumb">
              <div class="partner__thumb-inside"
                   :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>
            </div>

            <div class="partner__hover">
              <h3 class="partner__name">{{user.name}}</h3>
              <span class="partner__category text-center">Partner promocyjny</span>
              <span class="partner__link">Idź do partnera</span>
            </div>

          </a>
        </div>
      </div>

    </div>
  </main>
  <SectionInfo code="contact"/>
</template>

<script>
import SectionInfo from "./Home/SectionInfo";
import axios from "@/axios2";
import CoreArray from "@/helpers/array";
import ComponentListStateMachine from "@/Extends/ComponentListStateMachine";

export default {
  name: "Partners",
  components: {SectionInfo},
  extends: ComponentListStateMachine,

  data: () => ({
    users: [],
    count: 0,
  }),
  methods: {
    refresh: function () {

      this.stateUpdate('sendInfo');

      axios
          .get('user/get', {
            params: {
              sort: ['priority DESC','lastname ASC'],
              page: this.page,
              count: 40,
              show_on_list: "yes",
              role: "partner",
              scope: 'firstname,lastname,avatar_url,url_full,link_website'
            }
          })
          .then(response => {

            let arr = response.data.data;
            let column = "avatar_url";
            // let from = "https://off.radiokrakow.pl/api/";
            // let to = "https://off.radiokrakow.pl/";

            // arr = CoreArray.changeDomain(arr,column,from,to);

            arr = CoreArray.changeDomain(arr,'url_full','ludzie-radia/','zespol/');

            this.users = arr;
            this.count = response.data.count;

            this.stateUpdate('sendSuccess');

          })
          .catch(error => {
            this.refresh();

            this.stateUpdate('sendWrong');

          });


    }
  },
  created() {
    this.refresh();
  }

}
</script>

<style scoped>

.partner {
  margin-bottom: 20px;
  cursor: pointer;
  display: block;
  text-align: center;

  position: relative;

}


.partner__thumb {
  width: 100%;
  height: 0;

  padding-bottom: 67%;
  position: relative;

  background-color: #f5f5f5;
  margin-bottom: 12px;
}

.partner__thumb-inside {
  position: absolute;

  left: 24px;
  top: 24px;
  right: 24px;
  bottom: 24px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

}

.partner__name {
  /*width: 100%;*/
  height: 24px;

  text-align: center;

  font-weight: 600;

  font-size: 1.3rem;

  margin: 0;

  position: absolute;

  top: 12px;
  left: 12px;

  display: none;
}

.partner__category {
  /*width: 100%;*/
  height: 24px;

  text-align: center;

  font-weight: 600;

  font-size: 1rem;

  margin: 0;

  position: absolute;

  top: 12px;
  left: 12px;
}

.partner__link {
  position: absolute;

  bottom: 12px;
  right: 12px;
}

.partner__hover {
  display: block;

  position: absolute;

  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  opacity: 0;
  background-color: rgba(0, 0, 0, .7);

  color: #fff;

  z-index: 2;

  transition: .3s;
}

.partner:hover .partner__hover {
  opacity: 1;
  display: block;
  background-color: rgba(0, 0, 0, .7);
}


</style>